import Volunteers from "../images/Volunteers.jpg";

const HomeModalContent = [
  {
    // title: (
    //   <text>
    //     Coronation Celebration Event!
    //     <br />
    //     (Wednesday May 3rd)
    //   </text>
    // ),
    text: [
      "Please explore our website for background information and contact details - starting by scrolling down this Home Page.",
      "If you live in, or around, Saxmundham, we invite you to join our Facebook Page (contact on Latest News page). Here you will find the very latest 2025 news and amusing items, including special events and entertainment...",
    ],
  },
];

export default HomeModalContent;
