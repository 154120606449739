export const MenuContent = {
  section: [
    {
      title: "Snacks",
      items: [
        { item: "Bread or toast and butter (per slice)", price: "50p" },
        { item: "Cup-a-soup", price: "£1.00" },
        { item: "Beans on toast (1 round)", price: "£1.00" },
        { item: "Egg on toast (1 round)", price: "£1.00" },
        { item: "Toasted teacake with butter", price: "£1.25" },
        { item: "Slice of cake", price: "£2.00" },
        { item: "Long sausage roll", price: "£2.00" },
        { item: "Mac and cheese", price: "£2.00" },
      ],
    },
    {
      title: "Toasted sandwiches",
      items: [
        { item: "Ham or cheese or tomato", price: "£2.00" },
        { item: "Ham & cheese", price: "£2.50" },
        { item: "Tuna melt (Tuna & cheese)", price: "£3.00" },
      ],
    },
    {
      title: "Extras (add to any order)",
      items: [
        { item: "Jam", price: "25p" },
        { item: "Baked beans", price: "50p" },
        { item: "Cheese", price: "50p" },
        { item: "Ham", price: "50p" },
        { item: "Mushrooms", price: "50p" },
        { item: "Onions", price: "50p" },
        { item: "Tomato", price: "50p" },
        { item: "Egg", price: "70p" },
        { item: "Bacon rasher", price: "90p" },
        { item: "Sausage", price: "90p" },
        { item: "Tuna", price: "£1.00" },
        { item: "Coleslaw", price: "£1.00" },
        { item: "Potato wedges / Hash browns", price: "£2.00" },
      ],
    },
    {
      title: "Breakfasts",
      items: [
        {
          item: "'All-day' breakfast",
          price: "£5.50",
          label: (
            <text>
              Bacon, sausage, egg,
              <br />
              tomato, mushrooms,
              <br />
              baked beans
              <br />
              and a slice of toast
            </text>
          ),
        },
        {
          item: "The 'Hearty breakfast'",
          price: "£8.00",
          label: (
            <text>
              2 rashers of bacon, 2 sausages,
              <br />
              2 eggs, tomato,
              <br />
              mushrooms,
              <br />
              baked beans
              <br />
              and 2 slices of toast
            </text>
          ),
        },
        {
          item: "The 'Veggie' breakfast",
          price: "£5.50",
          label: (
            <text>
              Veggie burger, veggie sausage, egg,
              <br />
              tomato, mushrooms,
              <br />
              baked beans
              <br />
              and a slice of toast
            </text>
          ),
        },
        {
          item: "The 'Hearty veggie' breakfast",
          price: "£8.00",
          label: (
            <text>
              Veggie burger, 2 veggie sausages,
              <br />
              2 eggs, tomato,
              <br />
              mushrooms,
              <br />
              baked beans
              <br />
              and 2 slices of toast
            </text>
          ),
        },
      ],
    },
    {
      title: "Baps",
      items: [
        { item: "Ham or cheese or tomato", price: "£1.50" },
        { item: "Ham & cheese", price: "£2.00" },
        { item: "Tuna mayo", price: "£2.75" },
        {
          item: "Burgers",
          price: "£3.50",
          label: <text>(Pork / beef / chicken / veggie)</text>,
        },
        {
          item: "Fish finger",
          price: "£3.50",
          label: <text>(2 giant fish fingers)</text>,
        },
        { item: "Bacon", price: "£3.50", label: <text>(2 rashers)</text> },
        { item: "Sausage", price: "£3.50", label: <text>(2 sausages)</text> },
        {
          item: "SMILE Club",
          price: "£5.00",
          label: (
            <text>
              (2 slices roast chicken, 2 rashers back bacon, gem lettuce,
              sliced vine tomato)
            </text>
          ),
        },
      ],
    },
    {
      title: "Jacket potatoes",
      items: [
        {
          item: "Jacket potato with butter",
          price: "£2.50",
          label: <text>(with choice of fillings, see extras above)</text>,
        },
      ],
    },
    {
      title: "Omelettes",
      items: [
        {
          item: "Plain (1 egg)",
          price: "£1.00",
        },
        {
          item: "Plain (2 eggs)",
          price: "£2.00",
          label: <text>(with choice of fillings, see extras above)</text>,
        },
      ],
    },
    {
      title: "Hot and cold drinks",
      items: [
        { item: "Fruit juice", price: "60p" },
        { item: "Tea (choise of teas)", price: "£1.00" },
        {
          item: "Bottled water",
          price: "£1.00",
        },
        { item: "Canned drinks", price: "£1.20" },
        { item: "Coffee (Instant)", price: "£1.20" },
        { item: "Coffee (Espresso)", price: "£1.75" },
        { item: "Coffee (Americano)", price: "£1.75" },
        { item: "Coffee (Latte)", price: "£1.75" },
        { item: "Hot chocolate", price: "£2.00" },
      ],
    },
    {
      title: "Ice creams",
      items: [
        { item: "Cornetto", price: "£1.50" },
        { item: "Magnum", price: "£1.50" },
      ],
    },
  ],
};

export const Extras = [
  {
    title: "Allergy information",
    content: [
      <strong>Attention customers with food allergies.</strong>,
      "Please be aware that our food may contain or come into contact with common allergens such as dairy, eggs, wheat, soybeans, free nuts, peanuts, fish or shellfish.",
    ],
  },
];

export default MenuContent;
